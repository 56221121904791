import {Inject, Injectable} from '@angular/core';
import {take, tap} from 'rxjs/operators';
import {CatalogType} from '../../../pages/catalog/routing/CatalogType';
import {IdentityService} from '../../users/core/identity.service';
import {WorkType} from './api/monolith-api-client';
import {CatalogDialogsFacade} from '../dialogs/catalog-dialogs-facade.service';
import {WINDOW_TOKEN} from '../../../infrastructure/dom/WINDOW_TOKEN';

@Injectable({providedIn: 'root'})
export class CatalogLimitingService {
    private static CATALOG_TYPE_DATA: {
        [key in Exclude<CatalogType, CatalogType.Undefined>]
        : { key: string, limit: number }
    } = {
        'resume': {key: 'ResumeLimitedViewsService', limit: 5},
        'vacancy': {key: 'VacancyLimitedViewsService', limit: 10},
    };

    private readonly localStorage: Storage;

    constructor(
        private readonly identityService: IdentityService,
        private readonly catalogDialogs: CatalogDialogsFacade,
        @Inject(WINDOW_TOKEN) window: Window,
    ) {
        this.localStorage = window?.localStorage;
    }

    public addView(catalog: CatalogType, id: number, workType: WorkType, utmSource: string): void {
        if (!this.localStorage) {
            return;
        }

        this.identityService.stateChanges.pipe(
            take(1),
            tap((authState) => !authState.user && utmSource !== 'trudru' && this.increment(catalog, id, workType)),
        ).subscribe();
    }

    private getWatchedIds(catalog: CatalogType): number[] {
        const key = CatalogLimitingService.CATALOG_TYPE_DATA[catalog].key;
        const payloadJson = this.localStorage.getItem(key);
        const payloadData = payloadJson && JSON.parse(payloadJson);

        return Array.isArray(payloadData) ? payloadData : [];
    }

    private increment(catalog: CatalogType, id: number, workType: WorkType): void {
        const watchedIds = this.getWatchedIds(catalog);
        const limit = CatalogLimitingService.CATALOG_TYPE_DATA[catalog].limit;
        if (watchedIds.length <= limit) {
            this.addNewWatchedId(watchedIds, catalog, id);
        }
        if (watchedIds.length <= limit) {
            return;
        }
        this.catalogDialogs.openCatalogViewLimitation({
            catalog: catalog,
            workType: workType,
        }).subscribe();
    }

    private addNewWatchedId(ids: number[], catalog: CatalogType, id: number): void {
        if (ids.some((watchedId) => watchedId === id)) {
            return;
        }
        ids.push(id);
        const key = CatalogLimitingService.CATALOG_TYPE_DATA[catalog].key;
        this.localStorage.setItem(key, JSON.stringify(ids));
    }
}
