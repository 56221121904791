@if (context$ | async; as context) {

    <div class="nav-header">
        <nn-user-info class="nav-header__account"
            [user]="context.user"/>
        <nn-notifications-header class="nav-header__button"
            viewMode="short"/>
    </div>
    @if (context.user.isWorker) {
        <nn-worker-interesting/>
    }
    <nav class="navigation">
        <a class="navigation__link"
            routerLink="/account"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|dashboard' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileDashboard"
                    svgIcon="sprite:icon-profileDashboard"/>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|dashboard" | localization }}
            </span>
        </a>
        @if (context.user.isWorker) {
            <a class="navigation__link"

                routerLink="/favorites"
                routerLinkActive="navigation__link_active"
                [routerLinkActiveOptions]="{exact: true}"
                [title]="'dashboard_page|favorites' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileFavorites2"
                    svgIcon="sprite:icon-profileFavorites2"/>
            </span>
                <span class="navigation__content">
                {{ "dashboard_page|favorites" | localization }}
            </span>
            </a>
        }
        <a class="navigation__link"
            routerLink="/account/guests"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|guests' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileGuest2"
                    svgIcon="sprite:icon-profileGuest2"/>
                @if (0 | nnTotalUnreadGuestsCount | async; as unreadGuestsCount) {
                    <div class="navigation__count">
                    {{ unreadGuestsCount }}
                </div>
                }
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|guests" | localization }}
            </span>
        </a>
        <a class="navigation__link"
            *nnUserRoleOnly="'Employer'"
            routerLink="/account/vacancy-applications"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|vacancy_applications' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileGuest2"
                    svgIcon="sprite:icon-profileGuest2"/>
                @if (0 | nnTotalUnreadVacancyApplicationsCount | async; as unreadApplicationsCount) {
                    <div class="navigation__count">
                    {{ unreadApplicationsCount }}
                </div>
                }
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|vacancy_applications" | localization }}
            </span>
        </a>
        @if (context.user.isWorker) {
            <a class="navigation__link"

                routerLink="/account/recommendations"
                routerLinkActive="navigation__link_active"
                [routerLinkActiveOptions]="{exact: true}"
                [title]="'dashboard_page|recommendations' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileRecs2"
                    svgIcon="sprite:icon-profileRecs2"/>
            </span>
                <span class="navigation__content">
                {{ "dashboard_page|recommendations" | localization }}
            </span>
            </a>
        }
        @if (context.user.isEmployer) {
            <a class="navigation__link"

                routerLink="/account/vacancy-management"
                routerLinkActive="navigation__link_active"
                [routerLinkActiveOptions]="{exact: true}"
                [title]="'dashboard_page|vacancy' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileVacancy"
                    svgIcon="sprite:icon-profileVacancy"/>
            </span>
                <span class="navigation__content">
                {{ "dashboard_page|vacancy" | localization }}
            </span>
            </a>
        }
        <a class="navigation__link"
            routerLink="/account/profile"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|personal_data' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileUser"
                    svgIcon="sprite:icon-profileUser"/>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|personal_data" | localization }}
            </span>
        </a>
        <a class="navigation__link"
            routerLink="/account/orders"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|purchases' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profilePayment"
                    svgIcon="sprite:icon-profilePayment"/>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|purchases" | localization }}
            </span>
        </a>
        @if (context.isReferralsEnabled) {
            <a class="navigation__link"

                routerLink="/referrals"
                routerLinkActive="navigation__link_active"
                [routerLinkActiveOptions]="{exact: true}"
                [title]="'dashboard_page|referrals' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileFriends"
                    svgIcon="sprite:icon-profileFriends"/>
            </span>
                <span class="navigation__content">
                {{ "dashboard_page|referrals" | localization }}
            </span>
            </a>
        }
        <a class="navigation__link"
            routerLink="/account/preferences"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|settings' | localization">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileSettings"
                    svgIcon="sprite:icon-profileSettings"/>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|settings" | localization }}
            </span>
        </a>
        <!--<a
            class="navigation__link"
            routerLink="/account/background-check"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|background_check' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_backgroundCheck"
                    svgIcon="sprite:icon-shield"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|background_check" | localization }}
            </span>
        </a>-->
        <a class="navigation__link"
            [title]="'dashboard_page|exit' | localization"
            (click)="logout()">
            <span class="navigation__media">
                <mat-icon class="navigation__icon mat-icon_profileExit2"
                    svgIcon="sprite:icon-profileExit2"/>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|exit" | localization }}
            </span>
        </a>
    </nav>
    @if (context.user.isEmployer) {
        <button class="postajob"

            type="button"
            routerLink="/register/employer-success">
            <div class="postajob__media">
                <img class="postajob__img"
                    src="/assets/img/menu/postajob.svg"
                    alt="Post a job"/>
            </div>
            <div class="postajob__text">
                {{ "dashboard_page|employer_post_job" | localization }}
            </div>
        </button>
    }

}
